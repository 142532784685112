import { useEffect, useState } from "react"
import Iframe from "react-iframe"



export const Planner5d = () => {
    const [url, setUrl] = useState('https://square-block-2c30.akqadev.workers.dev/editor');
    return(
        <Iframe url={url}
            width="100%"
            height="100%"
            display="inline"
            position="relative"
        ></Iframe>
    )
}