import React, { useState } from 'react'
import Iframe from 'react-iframe';

export const RoomPlanner = () => {
    const [url, setUrl] = useState('https://roomplanner.marxent.com/');
    return(
        <Iframe url={url}
            width="100%"
            height="100%"
            display="inline"
            position="relative"
        ></Iframe>
    )
}
     