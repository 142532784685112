import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject, Drawing, PolygonDrawingTool, InkDrawingTool } from '@syncfusion/ej2-react-pdfviewer';
import * as ReactDOM from 'react-dom';
import * as React from 'react';

export const PdfViewer = () => {
    return(
        <PdfViewerComponent id="container" documentPath="Hillside 35 _ Floorplan _ Porter Davis.pdf" serviceUrl="https://buni-buildpro-test.azurewebsites.net/api/pdfviewer" enableFormDesignerToolbar={true} style={{ 'height': '100%' }}>
                <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
        </PdfViewerComponent>
    )
}