import React from 'react'
import Iframe from 'react-iframe';

export const FloorPlanner = () => {
    // overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:150%;width:150%;position:absolute;top:0px;left:0px;right:0px;bottom:0px
    const url = "https://floorplanner.com/demo ";
    return (
        <Iframe url={url}
        width="100%"
        height="100%"       
        position="relative"></Iframe>
    )
}
