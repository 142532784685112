import "@nylas/components-composer";
import { useEffect, useRef, useState } from "react";
export const Composer = () => {
    const [, setMsg] = useState({});
    const composer = useRef(null);
    const initialValues = {
        from: [
          {
            name: "Arif Test",
            email: "arif.uzzaman@akqa.com"
          }
        ],
        to: [
          {
            name: "Test User",
            email: "test@outlook.com"
          }
        ],
        subject: "Testing editor for demo purpose",
        body: "This is a Sample Body"
      };
      const internalData = [
        { id: 1, email: "test@akqa.com" },
        { id: 2, email: "test2@hotmail.com" },
        { id: 3, email: "arif.uzzaman@gmail.com" }
      ];

      useEffect(() => {
        const el = composer.current;
        el.change = (msg) => {
          setMsg(msg);
        };
        el.value = initialValues;
        el.show_header = true;
        el.show_subject = true;
        el.mode = "inline";
        el.theme = "light";
        el.show_editor_toolbar = true;
        el.show_from = true;
        el.show_to = true;
        el.to = {};
        el.from = internalData;
        // el.send = send;
      }, []);

    return(
        <div id="app">
            <div className="wrapper">
                <div style={{ textAlign: "left" }}>
                <nylas-composer
                    ref={composer}
                    id="c31f0c3c-4526-4e8b-ba0e-12b12ba083b3"
                ></nylas-composer>
                </div>
            </div>
        </div>
    )
}