import React, { Component, useRef, useState } from 'react';
import { render } from 'react-dom';
import { Stage, Layer, Image, Line, Text, Rect } from 'react-konva';
import useImage from 'use-image';
import './Konva.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const LionImage = ({newWidth, newHeight}) => {
    const [image] = useImage('https://www.roomsketcher.com/wp-content/uploads/2017/11/RoomSketcher-Order-Floor-Plans-2D-Floor-Plan.jpg');
    return <Image x="0" y="0" scaleX="1" scaleY="1" image={image} />;
    
};

export const KonvaComponent = () => {

    const [unitLabel, setUnitLabel] = useState('m');
    const [annotations, setAnnotations] = useState([]);
    const [newAnnotation, setNewAnnotation] = useState([]);
    const [isLineModel, setIsLineModel] = useState(true);

    //poly
    const [points, setPoints] = useState([]);
    const [curMousePos, setCurMousePos] = useState([0,0]);
    const [isMouseOverStartPoint, setIsMouseOverStartPoint] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isPolyMode, setIsPolyMode] = useState(false);

    const [valueWidth, setValueWidth] = useState("");
    const [valueHeight, setValueHeight] = useState("");
    const [stageScale, setStageScale] = useState(1);
    const [stageX, setStageX] = useState(50);
    const [stageY, setStageY] = useState(100);
    const _dlines = useRef([]);

    const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = useState([]);
    let _drawing = React.useRef(false);
    const [title, setTitle] = useState("");
    const [cursorLocation, setCursorLocation] = useState("");
    const [measureDistance, setMeasureDistance] = useState(0);

    const [checked, setChecked] = React.useState(true);
    const [isAllowMeasure, setIsAllowMeasure]  = useState(false);

    //distance
    const [measureWidth, setMeasureWidth] = useState(0);
    const [actualWidth, setActualWidth] = useState(0);

    const [measureHeight, setMeasureHeight] = useState(0);
    const [actualHeight, setActualHeight] = useState(0);

    const [measuredResult, setMeasuredResult] = useState(0);

    //steps
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(true);
    const [step3, setStep3] = useState(true);
    const [msg, setMsg] = useState("Please draw horizontal line on the plan");

    const getMousePos = stage => {
      return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    };

    const handleMouseDown = (e) => {
      if(isFinished){
        setIsFinished(false);
        setCurMousePos([0.0]);
        setPoints([]);
      }
    }
    const polygonArea = (vertices)  =>
    { 
        const X = [];
        const Y = [];
        const numPoints = vertices.length - 1;
        for(var v=0; v < numPoints; v++){
          X.push(vertices[v].x);
          Y.push(vertices[v].y);
        }
        var area = 0;   // Accumulates area 
        var j = numPoints-1; 
        var verticsCount = 0;
        var totaldx = 0;
        var totaldy = 0;
        var totalDistance = 0;
        for (var i=0; i < numPoints - 1; i++)        
        { 
          verticsCount++;
          const distance = Math.hypot(Math.abs(X[j]) - Math.abs(X[i]), Math.abs(Y[j]) - Math.abs(Y[i]));
          const distinY = Math.abs(Y[j] - Y[i]);
          const distinX = Math.abs(X[j] - X[i]);

          //const dx = (measureWidth/distance) * (distinX);
          //const dy = (measureHeight/distance) * (distinY);
          totaldx = totaldx + distinX;
          totaldy = totaldy + distinY;
          totalDistance = totalDistance + distance;
          j = i;  //j is previous vertex to i
        }
        const dx = (measureWidth/totalDistance) * totaldx;
        const dy = (measureWidth/totalDistance) * totaldy;
        return (dx * dy)/(verticsCount + 2);
    }

    const handleMouseUp = (e) => {
        if(!step1 || !step2) return;
        const stage = e.target.getStage();
        _drawing.current = !_drawing.current;
        const oldScale = stage.scaleX();
        
        //poly
        if(isPolyMode){
          console.log(isPolyMode);
          const stage = e.target.getStage();
          const mousePos = stage.getPointerPosition()
          const mousePointTo = {
            x: mousePos.x / oldScale - stage.x() / oldScale,
            y: (mousePos.y / oldScale - stage.y() / oldScale),
          };
          
          if (isFinished) {
            //calculate the ara of polygon here
            console.log("polygon", points);
            return;
          }
          _dlines.current.push({x: mousePointTo.x, y: mousePointTo.y});

          if (isMouseOverStartPoint && points.length >= 3) {
            console.log("polygon", _dlines.current);    
            const polyArea = polygonArea(_dlines.current);
            setMeasuredResult("Poly Area: " + (polyArea).toFixed(2) + ` ${unitLabel} x ${unitLabel} x ${unitLabel}`);
            _dlines.current = [];
            setIsFinished(true);
          } else {
           setPoints([...points, [mousePointTo.x, mousePointTo.y]]);
          }
          
          return;
        }
        //rectangle
        
        if (newAnnotation.length === 0 && !isLineModel) {
          const pos = e.target.getStage().getPointerPosition();
          const mousePointTo = {
            x: pos.x / oldScale - stage.x() / oldScale,
            y: (pos.y / oldScale - stage.y() / oldScale),
          };
          setNewAnnotation([{ x:mousePointTo.x, y:mousePointTo.y, width: 0, height: 0, key: "0" }]);
        }

        if (newAnnotation.length === 1 && !isLineModel) {
          const sx = newAnnotation[0].x;
          const sy = newAnnotation[0].y;
          const pos = e.target.getStage().getPointerPosition();
          const mousePointTo = {
            x: pos.x / oldScale - stage.x() / oldScale,
            y: (pos.y / oldScale - stage.y() / oldScale),
          };

          const annotationToAdd = {
            x: sx,
            y: sy,
            width: mousePointTo.x - sx,
            height: mousePointTo.y - sy,
            key: annotations.length + 1
          };
          annotations.push(annotationToAdd);
          setNewAnnotation([]);
          setAnnotations(annotations);
        }


        const pos = e.target.getStage().getPointerPosition();
        

        const mousePointTo = {
          x: pos.x / oldScale - stage.x() / oldScale,
          y: (pos.y / oldScale - stage.y() / oldScale),
        };

        setLines([...lines, { tool, points: [mousePointTo.x, mousePointTo.y] }]);
        _dlines.current.push({x: mousePointTo.x, y: mousePointTo.y});

        //calculate distance
        calculateDistance();
        
        calculateMeasuredDistance();
        //calculate distance
    };

    const calculateMeasuredDistance = () => {
      console.log(isAllowMeasure);
      if(isAllowMeasure){
        //calculating actual distance
        if(_dlines.current.length === 2){
          const firstPoint = _dlines.current[0];
          const secondPoint = _dlines.current[1];
                     
          if(Math.abs(firstPoint.x - secondPoint.x) <= 10)
          {
            //distance
            console.log("X found same means distance is in Y")
            const relativeY = (measureHeight/actualHeight) * (Math.abs(firstPoint.y - secondPoint.y));
            setMeasuredResult("Changes in Y and the distance is: " + (relativeY).toFixed(2) + ` ${unitLabel}`);
          }
          else if(Math.abs(firstPoint.y - secondPoint.y) <= 10){
            //distance
            console.log("Y found same means distance is in X")
            const relativeX = (measureWidth/actualWidth) * (Math.abs(firstPoint.x - secondPoint.x));
            setMeasuredResult("Changes in X and the distance is: " + (relativeX).toFixed(2) + ` ${unitLabel}`);
          }
          else {
            if(isLineModel === false){ //this is for rect
              const distinY = Math.abs(firstPoint.y - secondPoint.y);
              const distinX = Math.abs(firstPoint.x - secondPoint.x);
              console.log("distinY", distinY);
              const height = (measureHeight/actualHeight) * distinY;
              const width = (measureWidth/actualWidth) * (distinX)
              const rectArea =  height * width;
              console.log("height", height);
              console.log("width", width);
              
              setMeasuredResult("Area : " + (rectArea).toFixed(2) + ` ${unitLabel}x${unitLabel}`);
            }
          }
          _dlines.current = [];
            //var relativeDistance = 
        }        
      }
    }

    const calculateDistance = () => {
      if(isAllowMeasure) return;
      if(_dlines.current.length === 2){
        const firstPoint = _dlines.current[0];
        const secondPoint = _dlines.current[1];
        //distance
        var distance = Math.hypot(Math.abs(secondPoint.x) - Math.abs(firstPoint.x), Math.abs(secondPoint.y) - Math.abs(firstPoint.y));
        setMeasureDistance(distance);
        _dlines.current = [];
        setTitle(`Selected distance : ${distance}`);
        if(!measureWidth || measureWidth === 0 ){
          setStep1(false); 
          setStep2(true);
          setStep3(true);
        }
        else if(measureWidth && measureWidth > 0){
          setStep1(true); 
          setStep2(false);
          setStep3(true);
        }

               
        // setMsg("Please scale vertical line")
      }
    }

    const handleMouseOverStartPoint = event => {
      if (isFinished || points.length < 3) return;
      event.target.scale({ x: 2, y: 2 });
      setIsMouseOverStartPoint(true);
    };

    const handleMouseOutStartPoint = event => {      
      event.target.scale({ x: 1, y: 1 });
      setIsMouseOverStartPoint(false);      
    };


    const handleDragOutPoint = event => {
      console.log("end", event);
    };

    const handleMouseMove  = (e) => {
        if(!step1 || !step2) return;
        

        const stage = e.target.getStage();
        const oldScale = stage.scaleX();

        //poly
        if(isPolyMode){
          const mousePos = stage.getPointerPosition();
          const mousePointTo = {
            x: mousePos.x / oldScale - stage.x() / oldScale,
            y: (mousePos.y / oldScale - stage.y() / oldScale),
          };

          setCurMousePos([mousePointTo.x, mousePointTo.y]);
          return;
        }

        if(!_drawing.current) return;
        //rectangle
        if (newAnnotation.length === 1 && !isLineModel) {
          const sx = newAnnotation[0].x;
          const sy = newAnnotation[0].y;
          const pos = e.target.getStage().getPointerPosition();
          const mousePointTo = {
            x: pos.x / oldScale - stage.x() / oldScale,
            y: (pos.y / oldScale - stage.y() / oldScale),
          };

          setNewAnnotation([
            {
              x: sx,
              y: sy,
              width: mousePointTo.x - sx,
              height: mousePointTo.y - sy,
              key: "0"
            }
          ]);
        }

        //line
       
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        if(!lastLine || !lastLine.points){
          console.log("Points found null..");
          return;
        }

        const pos = e.target.getStage().getPointerPosition();
       

        const mousePointTo = {
          x: pos.x / oldScale - stage.x() / oldScale,
          y: (pos.y / oldScale - stage.y() / oldScale),
        };

        lastLine.points = [lastLine.points[0], lastLine.points[1], mousePointTo.x, mousePointTo.y]
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
        setCursorLocation(`${point.x} , ${point.y}`);

    }

    const clearPlan = () => {
      reset();
    }

    const handleChangeValueWidth = (e) => {
      setValueWidth(e.target.value);
    };

    const handleChangeValueHeight = (e) => {
      setValueHeight(e.target.value);
    };

    const clearValue = () => {
      setValueWidth("");
      setValueHeight("");
      setLines([]);
      setPoints([]);
      setCurMousePos([0.0]);
      setIsFinished(false);

      setLines([]);
      setAnnotations([]);
    }

    const handleWidth = (e) => {
      if(!measureDistance || measureDistance === 0){
        alert("Draw the max width first")
        return;
      }

      if(!valueWidth || valueWidth === 0){
        alert("Width cannot be mepty.")
        return;
      }

      setMeasureWidth(Number.parseFloat(valueWidth));
      setActualWidth(Number.parseFloat(measureDistance));
      setMeasureDistance(0);
      setStep1(true);
      setStep2(true);
      setStep3(true);
      setMsg("Please draw vertical line on the plan");
    }

    const handleHeight = () => {
      if(!measureDistance || measureDistance === 0){
        alert("Draw the max height first")
        return;
      }

      if(!valueHeight || valueHeight === 0){
        alert("Height cannot be mepty.")
        return;
      }

      setMeasureHeight(valueHeight);
      setActualHeight(measureDistance);
      setMeasureDistance(0);

      setStep1(true);
      setStep2(true);
      setStep3(false);
      setMsg("You are now allowed to measure the plan and Take off.");
      setIsAllowMeasure(true);
      setLines([]);
    }

    const handleCheck = (e) => { 
      if(!measureWidth || measureWidth === 0 || !measureHeight || measureHeight === 0){
        alert("You need to measure the scale of the plan first");
      }  
      setLines([]);
      setMeasureDistance(0);
      setStep1(true); 
      setStep2(true);
      setStep3(false);
    }

    const reset = () => {
      setIsLineModel(true);
      setLines([]);
      setPoints([]);
      setCurMousePos([0,0]);
      setIsFinished(false);
      setIsPolyMode(false);
      setAnnotations([]);
      setIsAllowMeasure(false);
      setMeasureDistance(0);
      setStep1(true); 
      setStep2(true);
      setStep3(true);
      setMeasureHeight(0);
      setMeasureWidth(0);
      setMsg("Please scale horizontal line")
    }

    const handleWheel = (e) => {
        const scaleBy = 1.02;
        e.evt.preventDefault();
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: pointer.x / oldScale - stage.x() / oldScale,
            y: pointer.y / oldScale - stage.y() / oldScale,
        };

        const newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStageScale(newScale);
        setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
        setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
    }

    const setLineSquare = (status) => {
      setIsLineModel(status);
      setIsPolyMode(false);
      setLines([]);
      setAnnotations([]);
    }

    const setPoly = (status) => {
      setIsPolyMode(status);
      setLines([]);
      setPoints([])
      setAnnotations([]);
      setIsLineModel(false);
      setIsFinished(false);
      setCurMousePos([0.0]);
    }
    //react dropdown
    const options = [
      'mm', 'cm', 'm'
    ];
    const defaultOption = options[0];

    const onSelctOption = (e) => {
      console.log(e);
      setUnitLabel(e.label);
    }

    

    const annotationsToDraw = [...annotations, ...newAnnotation];
    const flattenedPoints = points.concat(isFinished ? [] : curMousePos).reduce((a, b) => a.concat(b), []);
    return(
      <div>
        <div style={{top: "10px", left: "10px", width: "500px", height: "150px"}}>
          <button onClick={clearPlan} style={{marginTop: "2px", marginLeft: "20px"}}>Start over</button> 
          <button style={{marginTop: "2px", marginLeft: "10px", marginRight: "10px"}} onClick={clearValue}>Clear</button>
           <label style={{color: "darkcyan", fontWeight: "bold", fontSize: "1.2em"}}> {msg} </label>
          <input hidden={step1} style={{marginTop: "2px", marginLeft: "20px"}} type="text" placeholder="Enter horizontal measurement" value={valueWidth} onChange={handleChangeValueWidth}></input> <button hidden={step1} onClick={handleWidth}>Set</button>
          <input hidden={step2} style={{marginTop: "2px", marginLeft: "20px"}} type="text" placeholder="Enter vertical measurement" value={valueHeight} onChange={handleChangeValueHeight}></input> <button hidden={step2} onClick={handleHeight}>Set</button>
          <div style={{marginTop: "2px", marginLeft: "20px"}} hidden={step1 && step2}>
             <Dropdown style={{width: "20px"}} disabled = {!step2} options={options} onChange={e => onSelctOption(e)} value={defaultOption} placeholder="Select an Unit" />
          </div>
          
          <br></br><input hidden={step3} readOnly defaultChecked= {checked} style={{marginTop: "2px", marginLeft: "10px"}}  type="checkbox" onChange={e => handleCheck(!checked)}></input> <span hidden={step3}>Turn on measure mode</span>
          <div hidden={step3}>
            <button className={isLineModel ? "btn btn-warning" : "btn btn-info"} onClick={e => setLineSquare(true)}>Line</button>
            &nbsp; <button className={isLineModel || isPolyMode ? "btn btn-info" : "btn btn-warning"} onClick={e => setLineSquare(false)}>Square</button>
            &nbsp; <button className={!isPolyMode ? "btn btn-info" : "btn btn-warning"} onClick={e => setPoly(true)}>Poly</button>
          </div>
        </div>
        <Stage width={window.innerWidth} height={window.innerHeight} onMouseUp={handleMouseUp} 
        onMousemove={handleMouseMove} 
        onMouseDown = {handleMouseDown}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stageX}
        y={stageY}>
        <Layer>
          <LionImage newWidth={500} newHeight={500}  />
          {lines.map((line, i) => (
            <Line visible={isLineModel}
              key={i}
              points={line.points}
              stroke="#df4b26"
              strokeWidth={2}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation= {
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
          {annotationsToDraw.map(value => {
            return (
              <Rect visible={!isLineModel}
                x={value.x}
                y={value.y}
                width={value.width}
                height={value.height}
                stroke="#df4b26"
                strokeWidth={2}
                tension={0.5}              
                fill="#58181F"
                opacity="0.65"
              />
            );
          })}

            <Line visible={isPolyMode}
            points={flattenedPoints}
            stroke="red"
            fill = "#58181F"
            opacity="0.65"
            strokeWidth={2}
            closed={isFinished}
          />
          {isPolyMode && points.map((point, index) => {
            const width = 6;
            const x = point[0] - width / 2;
            const y = point[1] - width / 2;
            const startPointAttr =
              index === 0
                ? {
                    hitStrokeWidth: 12,                   
                    onMouseOver: handleMouseOverStartPoint,
                    onMouseOut: handleMouseOutStartPoint
                  }
                : null;
            return (
              <Rect
                key={index}
                x={x}
                y={y}
                width={width}
                height={width}
                fill="#58181F"
                opacity="0.65"
                stroke="red"
                strokeWidth={2}
                // onDragStart={handleDragStartPoint}
                // onDragMove={handleDragMovePoint}
                // onDragEnd={handleDragOutPoint}
                draggable
                {...startPointAttr}
              />
            );
          })}

          {/* <Text x={window.innerWidth/2 + 100} y = {30} text={title}></Text> */}
          {/* <Text x={window.innerWidth/2 + 100} y = {10} text={`Selected cursor location : ${cursorLocation}`}></Text> */}
          <Text x={window.innerWidth/2 + 100} y = {50} text={`Measured Distance : ${measuredResult}`}></Text>
        </Layer>
      </Stage>
    </div>
        
    )
}
