import React, { useRef } from 'react';

import EmailEditor from 'react-email-editor';


export const EmailEditorPage = () => {
    const emailEditorRef = useRef(null);
    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const {design, html} = data;
            console.log(`export html`, html);
            alert("html is exported to console. Check by pressing F12")
        });
    }
    const onLoad = () => {
         // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const onReady = () => {
        console.log(`onReady`);
    }

    return (
        <div>
            <div>
                <button onClick={exportHtml}>Export HTML</button>
            </div>
            <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
        </div>
    );
}